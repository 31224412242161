const { APPActions } = require("../actions");

const initState = {
    jwt: '',
    user: undefined,
    selectedEstate: undefined
};

const AppStateReducer = (state = initState, action) => {
    switch (action.type) {
        case APPActions.SET_AUTH_USER:
            return {
                ...state,
                ...action.payload
            }
            case APPActions.SET_SELECTED_ESTATE:
                return {
                    ...state,
                    selectedEstate: action.payload
                }
        case APPActions.LOGOUT:
            return initState;
        default:
            return state;
    }
};

export default AppStateReducer;

